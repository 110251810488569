import React, { useState } from "react";
import { BsPlusLg } from 'react-icons/bs';


function CreateArea(props) {
const [note, setNote] = useState({
	title: "",
	content: ""
});

function handleChange(event) {
	const { name, value } = event.target;

	setNote(prevNote => {
		return {
		...prevNote,
		[name]: value
		};
	});
}

function submitNote(event) {
	props.onAdd(note);
	setNote({
		title: "",
		content: ""
	});
	event.preventDefault();
}

return (
	<div>
		<form className="create-note">
		<input
			name="title"
			onChange={handleChange}
			value={note.title}
			placeholder="Title"
		/>
		<textarea
			name="content"
			onChange={handleChange}
			value={note.content}
			placeholder="Take a note..."
			rows="3"
		/>
		<button onClick={submitNote}><BsPlusLg /></button>
		</form>
	</div>
);
}

export default CreateArea;
